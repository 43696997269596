@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

.tools-page {
  background-color: #000000;
  color: white;
  height: 600px;
  width: 400px;
  border-radius: 10px;
  position: absolute;
  top: 2%;
  left: 39%;
  overflow-y: scroll;
}

.tools-page::-webkit-scrollbar {
  display: none;
}

/* market indices */
.indices {
  /* margin-top: 20px;
  margin-bottom: 10px; */
  padding-left: 18px;
}

/* market cards */
.market-cards-container {
  display: flex;
  gap: 10px;
  width: 100%;
  overflow-x: scroll;
  margin-top: 6px;
}

.market-cards-container::-webkit-scrollbar {
  display: none;
}

.market-card {
  background-color: #101419;
  height: fit-content;
  width: 200px;
  padding: 8px 5px;
  border-radius: 8px;
  text-align: left;
  cursor: pointer;
}

.market-card h5 {
  font-size: 13px;
  font-weight: 400;
}

.market-card p {
  font-size: 11px;
  font-weight: 300;
  white-space: nowrap;
}

.market-card p.red {
  color: rgb(253, 127, 127);
}

.market-card p.green {
  font-size: 13px;
  font-weight: 300;
  color: rgb(130, 249, 130);
}

/* option chain */
.option-chain {
  margin-top: 20px;
  padding-left: 18px;
}

.option-chain h2 {
  font-size: 15px;
  letter-spacing: 0.5px;
  font-weight: 600;
  color: #576678;
  text-transform: capitalize;
}

.options-container {
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  gap: 10px;
  overflow-x: scroll;
  overflow-y: hidden;
  margin-top: 12px;
}

.options-container::-webkit-scrollbar {
  display: none;
}

.options-container .option-card {
  background-color: #101419;
  padding: 4px 30px;
  border-radius: 8px;
  height: 50px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.options-container .option-card h4 {
  font-size: 13px;
  letter-spacing: 0.5px;
  font-weight: 500;
}

/* research  */
.research {
  margin: 20px 0;
  padding-left: 18px;
}

.research h2 {
  font-size: 16px;
  letter-spacing: 0.5px;
  font-weight: 600;
  color: #576678;
  text-transform: capitalize;
}

.research-cards {
  display: flex;
  gap: 20px;
  padding-left: 10px;
  margin-top: 15px;
}

.research-card {
  background-color: #101419;
  display: flex;
  padding: 20px 12px;
  align-items: center;
  gap: 10px;
  border-radius: 10px;
  cursor: pointer;
}

/* market updates */
.market-updates {
  margin: 20px 0;
  padding-left: 18px;
}

.market-updates h2 {
  font-size: 16px;
  letter-spacing: 0.5px;
  font-weight: 600;
  color: #576678;
  text-transform: capitalize;
}

.market-updates-cards {
  display: flex;
  gap: 20px;
  padding-left: 10px;
  margin-top: 15px;
}

.market-update-card {
  background-color: #101419;
  width: 130px;
  display: flex;
  align-items: center;
  gap: 15px;
  padding: 5px 15px;
  border-radius: 10px;
}

.market-icon {
  color: rgb(242, 91, 91);
  font-weight: 200;
}

.market-update-card p {
  font-size: 15px;
  letter-spacing: 0.5px;
  font-weight: 400;
}

/* investing tools */
.investing-tools {
  margin: 20px 0;
  padding-left: 18px;
}

.investing-tools h2 {
  font-size: 16px;
  letter-spacing: 0.5px;
  font-weight: 600;
  color: #576678;
  text-transform: capitalize;
}

.investing-tools-cards {
  display: flex;
  gap: 20px;
  padding-left: 10px;
  margin-top: 15px;
  overflow-x: scroll;
}

.investing-tools-cards::-webkit-scrollbar {
  display: none;
}

.investing-card {
  background-color: #101419;
  display: flex;
  align-items: center;
  gap: 15px;
  padding: 5px 20px;
  border-radius: 10px;
  cursor: pointer;
}

.investing-card p {
  font-size: 15px;
  letter-spacing: 0.5px;
}

.investing-card .investing-icon {
  color: rgb(242, 91, 91);
  font-weight: 200;
}
