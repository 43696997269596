.keyboard-shortcut-container {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
}

.keyboard-popup {
    position: absolute;
    top: 20px;
    right: 6px;
    margin: 2px;
    width: 250px;
    background-color: #101419;
    border: 1px solid #ccc;
    color: #fff;
    border-radius: 5px;
    padding: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: left;
    text-align: center;
}

.keyboard-shortcut-container .question-btn {
    cursor: pointer;
    border-radius: 50%;
    background: #101419;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    border: none;
    outline: none;
    width: 30px;
    margin-left: 2px;
}

.keyboard-section-title {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 10px;
}

.keyboard-shortcuts {
    display: grid;
    grid-template-columns: repeat(1, auto);
    gap: 10px;
}

.shortcut-item {
    display: flex;
    align-items: center;
}

.shortcut-item span:first-child {
    font-weight: bold;
    margin-right: 5px;
    color: white;
}

.shortcut-item span:nth-child(2) {
    font-size: 14px;
    color: white;
}