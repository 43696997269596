.trade-pnl-container {
  display: flex;
  background-color: #eee;
  margin-left: 4px;
  border-radius: 6px;
  color: #101419;
  align-items: center;
  padding: 0 10px;
  gap: 15px;
  width: 250px;
}

.trade-pnl-container .pnl {
  display: flex;
  flex-direction: column;
  align-items: center;
  white-space: nowrap;
  width: 100%;
  text-align: center;
}
