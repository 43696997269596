/* option chain css */
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

.option-chain-page {
  background-color: #000000;
  color: white;
  height: 600px;
  width: 400px;
  border-radius: 10px;
  position: absolute;
  top: 2%;
  left: 39%;
  overflow-y: scroll;
}

.option-chain-page::-webkit-scrollbar {
  display: none;
}

.page-container {
  height: 100%;
  width: 100%;
  position: relative;
}

.option-chain-header {
  display: flex;
  gap: 16px;
  align-items: center;
  padding: 15px 12px;
  background-color: #101419;
  position: relative;
}

.option-chain-header .header-item {
  border-bottom: 2px solid #fff;
  margin-right: 25px;
  cursor: pointer;
}

.option-chain-header .back-icon {
  height: 20px;
  width: 20px;
  cursor: pointer;
  margin-right: 10px;
}

.option-chain-header .header-item h4 {
  font-size: 15px;
  margin-bottom: 5px;
  margin-top: 5px;
  font-weight: 600;
  letter-spacing: 0.5px;
}

.option-chain-header .header-item p {
  font-size: 14px;
  margin-top: 5px;
  letter-spacing: 0.5px;
}

.option-chain-header .focus-icon {
  position: absolute;
  right: 20px;
  top: 20px;
  height: 30px;
  width: 30px;
  cursor: pointer;
}

/* secondary header */
.secondary-header {
  display: flex;
  align-items: center;
  background-color: #101010;
  padding: 15px 12px;
  border-bottom: 2px solid #608699;
}

.secondary-header .tabs {
  width: 50%;
  display: flex;
  background-color: #000000;
  margin-right: 15px;
  padding: 10px 10px;
  border-radius: 10px;
}

.secondary-header .tabs .tab {
  background-color: transparent;
  border: none;
  color: #fff;
  border: 2px solid #05a7f8;
  margin-right: 8px;
  border-radius: 8px;
  cursor: pointer;
  font-size: 15px;
  padding: 10px 15px;
}

.expiry-select-dropdown {
  background-color: #000000;
  margin-right: 15px;
  padding: 6px 10px;
  border: none;
  color: #fff;
  border-radius: 10px;
  cursor: pointer;
}

.secondary-header .filter-icon {
  height: 25px;
  width: 25px;
  margin-left: 8px;
  cursor: pointer;
}

/* search bar */
.option-search-input-div {
  position: relative;
  padding: 10px 10px;
  border: 2px solid #655c5c;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 12px;
  margin-bottom: 10px;
  margin-top: 10px;
  width: 90%;
}

.option-search-input-div input {
  background-color: transparent;
  border: none;
  outline: none;
  color: white;
  width: 85%;
}

.option-search-input-div .search-icon {
  margin-right: 8px;
  cursor: pointer;
}

/* call and put container */
.call-put-container {
  display: flex;
  justify-content: space-between;
  padding: 15px 20px;
  border-bottom: 2px solid #608699;
}

.call-put-container p {
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 16px;
  letter-spacing: 0.5px;
  font-weight: 600;
}

/* table */
.table-container {
  margin: 4px;
  width: 100%;
  overflow-x: scroll;
}

.table-container::-webkit-scrollbar {
  display: none;
}

table {
  border-collapse: collapse;
}

.table-row {
  cursor: pointer;
}

th,
td {
  border: 1px solid #101419;
  text-align: left;
  padding: 8px 4px;
  width: fit-content;
}

th {
  background-color: #101010;
}

/* call text */
.call-put-container .call-text {
  width: 140px;
}

.greeks-iv,
.greeks-strike-price {
  background-color: rgb(230, 218, 207);
  color: #000000;
  font-weight: 400;
}

.green-border {
  border-bottom: solid rgb(78, 182, 78);
  border-width: 2px;
}

.red-border {
  border-bottom: solid rgb(243, 76, 76);
  border-width: 2px;
}
