@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

a {
  color: #029fed;
}

a p {
  color: white;
}

.logout-icon {
  color: #fff;
}

.profile-page {
  background-color: #000000;
  color: white;
  height: 600px;
  width: 400px;
  border-radius: 10px;
  position: absolute;
  top: 2%;
  left: 39%;
  overflow-y: scroll;
}

.profile-page::-webkit-scrollbar {
  display: none;
}

.app-header {
  background-color: #101419;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  margin-bottom: 20px;
}

.app-header .header-icon {
  color: #029fed;
  cursor: pointer;
  font-weight: bold;
}

.app-header .page-title {
  font-size: 22px;
  color: white;
  font-weight: 600;
  letter-spacing: 0.5px;
}

.user-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 20px;
  background-color: #101419;
}

.user-details .user-name-container {
  display: flex;
  align-items: center;
}

.user-details .user-name-container .user-icon {
  margin-right: 20px;
  cursor: pointer;
}

.user-name {
  padding: 0;
  display: block;
}

.user-name h4 {
  text-transform: uppercase;
  font-size: 18px;
  letter-spacing: 0.5px;
  margin-bottom: 0px;
}

.user-name p {
  font-size: 14px;
  font-weight: 300;
}

.user-details .forward-icon {
  color: #029fed;
  cursor: pointer;
  font-weight: bold;
}

/* services cards */
.cards-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-top: 15px;
  padding: 10px;
}

.cards-container .service-card {
  background-color: #101417;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  align-items: center;
  gap: 4px;
  padding: 7px;
  border-radius: 10px;
}

.service-card .service-icon {
  color: #029fed;
  cursor: pointer;
  font-weight: bold;
}

.service-card p {
  font-size: 15px;
  text-transform: capitalize;
  letter-spacing: 0.5px;
  font-weight: 500;
}

/* refer and earn offer */

.offer-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 20px;
  background-color: #101419;
  margin-top: 15px;
}

.offer-details .offer-details-container {
  display: flex;
  align-items: center;
}

.offer-details .offer-details-container .gift-icon {
  margin-right: 20px;
  cursor: pointer;
  color: orange;
}

.offer-text h4 {
  text-transform: capitalize;
  font-size: 14px;
  letter-spacing: 0.5px;
  margin-bottom: 0px;
}

.offer-text p {
  font-size: 12px;
  font-weight: 300;
}

.offer-details .share-icon {
  color: #029fed;
  cursor: pointer;
  font-weight: bold;
}

/* tutorial cards */
.tutorial-cards {
  margin-top: 20px;
  margin-bottom: 30px;
}

.tutorial-card {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 18px 20px;
  background-color: #101419;
  margin-bottom: 10px;
}

.tutorial-card .icon {
  color: #029fed;
  cursor: pointer;
  font-weight: bold;
}

.tutorial-card .left-side {
  display: flex;
  align-items: center;
  gap: 16px;
}

.tutorial-card p {
  text-align: left;
  font-size: 18px;
  text-transform: capitalize;
}
