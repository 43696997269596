@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

body {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
}

.notifications-page {
  background-color: #000;
  color: white;
  height: 600px;
  width: 400px;
  border-radius: 10px;
  position: absolute;
  top: 2%;
  left: 39%;
  overflow-y: scroll;
}

.notifications-page .header-icon {
  cursor: pointer;
}

.notifications-page::-webkit-scrollbar {
  display: none;
}

.page-container {
  height: 100%;
  width: 100%;
  position: relative;
}

.page-container .notifications-header {
  background-color: #101419;
  height: 50px;
  margin-bottom: 35px;
  padding: 25px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}

.page-container .notifications-header .page-title {
  font-size: 18px;
  font-weight: 500;
}

.page-container .notifications-header .header-icon .svg {
  cursor: pointer;
  position: relative;
}

.page-container .notifications-header .header-icon p {
  position: absolute;
  top: 20px;
  background: #000;
  width: 20px;
  height: 20px;
  text-align: center;
  border-radius: 50%;
  right: 10px;
}

.content-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 300px;
}

.content-page .text-container {
  width: 80%;
  text-align: center;
}

.content-page .text-container p {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.5px;
  text-transform: capitalize;
  color: #5f6164;
}

/* notification cards */
.notification-cards {
  display: flex;
  flex-direction: column;
}

.notification-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #eee;
  padding: 10px 15px;
  margin-bottom: 2px;
}

.notification-card .notification-content {
  display: flex;
  flex-direction: column;
}

.notification-card .notification-content p {
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.5px;
  margin: 8px 0;
  color: #000;
}

.notification-card .notification-content span {
  font-size: 10px;
  font-weight: 400;
  letter-spacing: 0.5px;
  text-transform: capitalize;
  color: #101419;
}

.notification-card .notification-date p {
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.5px;
  text-transform: capitalize;
  color: #101419;
}
