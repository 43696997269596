@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

body {
  margin: 0;
  box-sizing: border-box;
  padding: 0;
  font-family: "Poppins", sans-serif;
}

.portfolio-page {
  background-color: #000000;
  color: white;
  height: 600px;
  width: 400px;
  border-radius: 10px;
  position: absolute;
  top: 2%;
  left: 39%;
  overflow-y: scroll;
}

.page-container {
  height: 100%;
  width: 100%;
  position: relative;
}

.portfolio-page::-webkit-scrollbar {
  display: none;
}

/* portfolio header */
.portfolio-header {
  background-color: #101419;
  padding: 5px 0 15px 4px;
}

.header-items {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 15px 0;
}

.portfolio-header .header-item {
  display: block;
  margin-right: 10px;
}

.portfolio-header .header-item h4 {
  font-size: 14px;
  letter-spacing: 0.5px;
  font-weight: 600;
  margin-bottom: 5px;
}

.portfolio-header .header-item p {
  font-size: 13px;
  margin-top: 10px;
}

.portfolio-header .header-item p span {
  color: rgb(239, 122, 122);
}

.header-icon {
  color: #029fed;
  cursor: pointer;
}

/* tabs */
.tabs {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  padding: 4px 10px;
  gap: 10px;
}

.tab {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.5s ease-in;
}

.tab.active {
  border-bottom: 2px solid #029fed;
}

.tab p {
  font-size: 15px;
  margin-top: 4px;
  margin-bottom: 4px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  cursor: pointer;
}

/* positions container */

.positions-container.active {
  background-color: #101419;
  padding: 10px 8px;
  height: auto;
}

.positions-container {
  padding: 10px 8px;
  height: 350px;
}

.position-card {
  display: flex;
  justify-content: space-between;
  border-bottom: 2px solid #4a4545;
  padding: 5px 10px;
  position: relative;
}

.pnl-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 5px;
  width: fit-content;
}

.pnl-container .position-square-off-btn {
  background-color: #ff5900;
  color: white;
  text-align: left;
  padding: 8px 10px;
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 5px;
}

.position-card .left .time {
  font-size: 14px;
  font-weight: 400;
  color: #857e7e;
  margin-bottom: 8px;
}

.position-card .left h3 {
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 10px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.position-card .left p {
  font-size: 14px;
  margin-top: 10px;
  letter-spacing: 0.5px;
  font-weight: 300;
}

.position-card .left p i {
  font-style: normal;
  text-transform: uppercase;
  color: #05a7f8;
}

/* :right */
.position-card .right span {
  font-size: 14px;
  font-weight: 400;
  color: #857e7e;
  margin-bottom: 8px;
}

.position-card .right h3 {
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 10px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.position-card .right p {
  font-size: 14px;
  margin-top: 10px;
  letter-spacing: 0.5px;
  font-weight: 300;
  background-color: rgb(61, 138, 61);
  color: #fff;
  text-align: center;
}

/* main-content */
.main-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 300px;
}

.main-content .text-container {
  width: 80%;
  text-align: center;
}

.main-content .text-container p {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.5px;
  text-transform: capitalize;
  color: #5f6164;
}

/* update price */
.update-price {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  margin-bottom: 20px;
  background-color: #101419;
}

.update-price p {
  font-size: 15px;
  text-transform: capitalize;
  font-weight: 400;
}

.update-price .visit-icon {
  cursor: pointer;
  color: #029fed;
}

/* modal table  */
.position-table-container {
  margin: 15px 0;
  width: 100%;
  width: fit-content;
}

.position-table-container::-webkit-scrollbar {
  display: none;
}

.modal-tsl-container {
  position: relative;
}

.modal-tsl-container .edit-btn {
  position: absolute;
  top: 0;
  right: 10px;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background-color: #ccc;
  color: #101419;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
}

.modal-tsl-container .position-table-container td .tsl-edit-input {
  background-color: #fff;
  color: #101010;
  width: 35px;
  border: 1px solid #101419;
}

.edit-btn .edit-close,
.edit-btn .edit-open {
  color: #000000;
  cursor: pointer;
}

.tsl-info-table {
  border-collapse: collapse;
  width: auto;
}

.position-table-body tr td {
  border: 1px solid #101419;
  text-align: left;
  padding: 15px 15px;
  overflow: hidden;
  font-size: 14px;
  width: auto;
}

.tsl-info-table .position-table-head .table-header {
  color: #101419;
  font-size: 10px;
  border: 1px solid #101419;
  text-align: left;
  padding: 10px 15px;
  overflow: hidden;
  width: auto;
  background-color: #f4f4f4;
}

.show-trails {
  cursor: pointer;
}
