/* navigation items */
.navigation-items {
  position: sticky;
  display: flex;
  align-items: center;
  gap: 20px;
  bottom: 0;
  background-color: #101419;
  width: 100%;
  height: 50px;
  padding: 18px 10px;
}

.navigation-items .item {
  cursor: pointer;
  display: flex;
  gap: 10px;
  flex-direction: column;
  margin-top: 20px;
  align-items: center;
}

.navigation-items .item .item-icon {
  color: #029fed;
  font-weight: bold;
  cursor: pointer;
}

.navigation-items .item .item-name {
  font-size: 10px;
  margin-top: 2px;
  cursor: pointer;
  text-transform: capitalize;
  color: #576678;
  font-weight: 500;
  letter-spacing: 0.5px;
}
