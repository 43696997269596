@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

body {
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
  box-sizing: border-box;
}

.orders-page {
  background-color: #000000;
  color: white;
  height: 600px;
  width: 400px;
  border-radius: 10px;
  position: absolute;
  top: 2%;
  left: 39%;
  overflow-y: scroll;
}

.page-container {
  height: 100%;
  width: 100%;
  position: relative;
}

.orders-page::-webkit-scrollbar {
  display: none;
}

/* portfolio header */
.orders-header {
  background-color: #101419;
  padding: 5px 0 15px 4px;
}

.header-items {
  display: flex;
  align-items: center;
  gap: 10px;
}

.orders-header .header-item {
  display: block;
  margin-right: 10px;
}

.orders-header .header-item h4 {
  font-size: 14px;
  letter-spacing: 0.5px;
  font-weight: 600;
  margin-bottom: 5px;
}

.orders-header .header-item p {
  font-size: 13px;
  margin-top: 10px;
}

.orders-header .header-item p span {
  color: rgb(239, 122, 122);
}

.header-icon {
  color: #029fed;
  cursor: pointer;
}

/* tabs */
.orders-tabs {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  padding: 4px 10px;
  gap: 10px;
}

.tab {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.5s ease-in;
}

.tab.active {
  border-bottom: 2px solid #029fed;
}

.tab p {
  font-size: 15px;
  margin-top: 4px;
  margin-bottom: 4px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  cursor: pointer;
}

.open-page {
  position: relative;
}

/* open orders container */
.open-orders-container.active {
  background-color: #101419;
  padding: 10px 8px;
  height: 400px;
  overflow-y: scroll;
}

.open-orders-container.active::-webkit-scrollbar {
  display: none;
}

.open-orders-container {
  background-color: #101419;
  padding: 10px 8px;
  height: 400px;
}

.open-card {
  display: flex;
  justify-content: space-between;
  border-bottom: 2px solid #4a4545;
  padding: 5px 10px;
  position: relative;
}

.close-edit-container {
  display: flex;
  gap: 5px;
  align-items: center;
}

.close-edit-container .close-icon,
.close-edit-container .edit-icon {
  cursor: pointer;
}

.open-card .left .time {
  font-size: 14px;
  font-weight: 400;
  color: #857e7e;
  margin-bottom: 8px;
}

.open-card .left h3 {
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 10px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.open-card .left p {
  font-size: 14px;
  margin-top: 10px;
  letter-spacing: 0.5px;
  font-weight: 300;
}

.open-card .left p i {
  font-style: normal;
  text-transform: uppercase;
  color: #05a7f8;
}

/* :right */
.open-card .right span {
  font-size: 14px;
  font-weight: 400;
  color: #857e7e;
  margin-bottom: 8px;
}

.open-card .right h3 {
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 10px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.open-card .right p {
  font-size: 14px;
  margin-top: 10px;
  letter-spacing: 0.5px;
  font-weight: 300;
  background-color: rgb(61, 138, 61);
  color: #fff;
  text-align: center;
}

/* content-page */
.content-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 450px;
}

.content-page .text-container {
  width: 80%;
  text-align: center;
}

.content-page .text-container p {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.5px;
  text-transform: capitalize;
  color: #5f6164;
}

/* update price */
.update-price {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  margin-bottom: 20px;
  background-color: #101419;
}

.update-price p {
  font-size: 15px;
  text-transform: capitalize;
  font-weight: 400;
}

.update-price .visit-icon {
  cursor: pointer;
  color: #029fed;
}
