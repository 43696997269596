@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

body {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
}

.funds-page {
  background-color: #101419;
  color: white;
  height: 600px;
  width: 400px;
  border-radius: 10px;
  position: absolute;
  top: 2%;
  left: 39%;
  overflow-y: scroll;
}

.funds-page .header-icon {
  cursor: pointer;
}

.funds-page::-webkit-scrollbar {
  display: none;
}

.page-container {
  height: 86%;
  width: 100%;
  position: relative;
}

.page-container .funds-header {
  background-color: #101419;
  height: 50px;
  margin-bottom: 35px;
  padding: 25px 20px;
}

.funds-header .top-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.funds-category {
  padding: 8px 20px;
  background-color: #000000;
  border: none;
  color: #fff;
  border-radius: 7px;
  cursor: pointer;
}

.funds-category option {
  background-color: #000000;
  color: #fff;
  cursor: pointer;
}

.funds-header .page-title {
  margin-top: 25px;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.5px;
  line-height: 20px;
}

/* middle cards */
.middle-cards {
  margin-top: 20px;
  margin-bottom: 20px;
  border-bottom: 2px solid #e7d1d1d6;
}

.middle-card-1 {
  align-items: center;
  display: flex;
  justify-content: space-between;
  gap: 20px;
  cursor: pointer;
  border-radius: 20px;
  height: fit-content;
  margin-bottom: 8px;
  margin-left: 10px;
  margin-right: 10px;
  padding: 18px 4px;
}

.middle-card p {
  font-size: 14px;
}

.middle-card {
  background-color: #0000003a;
  align-items: center;
  display: flex;
  justify-content: space-between;
  gap: 20px;
  cursor: pointer;
  border-radius: 20px;
  margin-bottom: 8px;
  height: fit-content;
  margin-left: 10px;
  margin-right: 10px;
  padding: 18px 4px;
}

.middle-card-1 p {
  font-size: 14px;
}

.middle-card-1 span,
.middle-card span {
  font-size: 14px;
  font-weight: 300;
}

.fund-cards {
  margin-bottom: 20px;
}
