@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

.trades-page {
  background-color: #000000;
  color: white;
  height: 600px;
  width: 400px;
  border-radius: 10px;
  position: absolute;
  top: 2%;
  left: 39%;
  overflow-y: scroll;
}

.trades-page::-webkit-scrollbar {
  display: none;
}

.trades-header {
  display: flex;
  align-items: center;
  padding: 1px 20px;
  gap: 8px;
  background-color: #101419;
}

.trades-header .input-div {
  position: relative;
  padding: 10px 10px;
  border: 2px solid #655c5c;
  display: flex;
  align-items: center;
  border-radius: 12px;
  margin-right: 5px;
}

.trades-page .page-container {
  position: relative;
}

.ce-btn,
.pe-btn {
  color: #101419;
  cursor: pointer;
  border-radius: 8px;
  margin: 4px 2px;
  background-color: #d74841;
  border: none;
  outline: none;
  padding: 2px 10px;
}

.ce-btn {
  background-color: #409215;
}

.trades-header .input-div .search-icon {
  margin-right: 8px;
  cursor: pointer;
}

.trades-header .input-div .menu-icon-trade {
  color: #fff;
  cursor: pointer;
}

.trades-header .input-div input {
  background-color: transparent;
  border: none;
  outline: none;
  color: white;
}

.trades-header .input-div input:focus {
  outline: none;
}

/* quick order */
.quick-order {
  margin-top: 15px;
  border-top: 1px solid #029fed;
}

.quick-order .order-text {
  font-size: 18px;
  text-align: center;
  text-transform: capitalize;
  font-weight: 600;
  letter-spacing: 0.5px;
  border-bottom: 1px solid #029fed;
  padding: 0 10px;
}

.price-ltp {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin-top: 10px;
}

.price-ltp h5 {
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0.5px;
}

.price-ltp span {
  font-size: 15px;
  color: rgb(78, 182, 78);
  font-weight: 600;
}

/* orders info container */
.orders-information-container {
  padding: 10px 15px;
  border-bottom: 1px solid #029fed;
  margin-top: 10px;
}

.information-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.information-row p {
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  width: 150px;
}

.information-row span {
  font-size: 20px;
  font-weight: 600;
  width: 50px;
}

.information-row .select-dropdown {
  width: 150px;
  border: 2px solid #029fed;
  outline: none;
  background-color: white;
  padding: 5px 10px;
  border-radius: 10px;
  color: #101419;
}

.information-row .input {
  width: 130px;
  border: 2px solid #029fed;
  outline: none;
  background-color: white;
  padding: 5px 10px;
  border-radius: 10px;
  color: #101419;
}

.information-row .btns {
  width: 42%;
  display: flex;
  align-items: center;
  gap: 10px;
}

.information-row .btns .buy-btn {
  padding: 8px 15px;
  text-transform: uppercase;
  color: white;
  background-color: rgb(78, 182, 78);
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 8px;
}

.information-row .btns .sell-btn {
  padding: 8px 15px;
  text-transform: uppercase;
  color: white;
  background-color: red;
  border: none;
  outline: none;
  border-radius: 8px;
  cursor: pointer;
}

/* .auto-strike  */
.auto-strike {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
  padding: 10px 20px;
}

.auto-strike p {
  text-transform: uppercase;
  font-size: 15px;
  letter-spacing: 0.5px;
}

.auto-strike-btns {
  display: flex;
  align-items: center;
}

.call-btn {
  background-color: rgb(78, 182, 78);
  color: white;
  border: none;
  outline: none;
  padding: 8px 20px;
  border-radius: 8px;
  margin-right: 10px;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: bold;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.put-btn {
  background-color: red;
  color: white;
  border: none;
  outline: none;
  padding: 8px 20px;
  border-radius: 8px;
  margin-right: 10px;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: bold;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.arrow-icon {
  width: 30px;
  height: 20px;
  font-weight: bolder;
  margin-left: 4px;
  cursor: pointer;
}

/* market indices */
.indices {
  /* margin-top: 20px;
  margin-bottom: 10px; */
  padding-left: 18px;
}

/* market cards */
.market-cards-container {
  display: flex;
  gap: 10px;
  width: 100%;
  overflow-x: scroll;
  margin-top: 6px;
}

.market-cards-container::-webkit-scrollbar {
  display: none;
}

.market-card {
  background-color: #101419;
  height: fit-content;
  width: 200px;
  padding: 8px 5px;
  border-radius: 8px;
  text-align: left;
  cursor: pointer;
}

.market-card h5 {
  font-size: 13px;
  font-weight: 400;
}

.market-card p {
  font-size: 11px;
  font-weight: 300;
  white-space: nowrap;
}

.market-card p.red {
  color: rgb(253, 127, 127);
}

.market-card p.green {
  font-size: 13px;
  font-weight: 300;
  color: rgb(130, 249, 130);
}

/* option chain */
.option-chain {
  margin-top: 20px;
  padding-left: 18px;
}

.option-chain h2 {
  font-size: 15px;
  letter-spacing: 0.5px;
  font-weight: 600;
  color: #576678;
  text-transform: capitalize;
}

.options-container {
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  gap: 10px;
  overflow-x: scroll;
  overflow-y: hidden;
  margin-top: 12px;
}

.options-container::-webkit-scrollbar {
  display: none;
}

.options-container .option-card {
  background-color: #101419;
  padding: 4px 30px;
  border-radius: 8px;
  height: 50px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.options-container .option-card h4 {
  font-size: 13px;
  letter-spacing: 0.5px;
  font-weight: 500;
}

/* research  */
.research {
  margin: 20px 0;
  padding-left: 18px;
}

.research h2 {
  font-size: 16px;
  letter-spacing: 0.5px;
  font-weight: 600;
  color: #576678;
  text-transform: capitalize;
}

.research-cards {
  display: flex;
  gap: 20px;
  padding-left: 10px;
  margin-top: 15px;
}

.research-card {
  background-color: #101419;
  display: flex;
  padding: 20px 12px;
  align-items: center;
  gap: 10px;
  border-radius: 10px;
  cursor: pointer;
}

/* market updates */
.market-updates {
  margin: 20px 0;
  padding-left: 18px;
}

.market-updates h2 {
  font-size: 16px;
  letter-spacing: 0.5px;
  font-weight: 600;
  color: #576678;
  text-transform: capitalize;
}

.market-updates-cards {
  display: flex;
  gap: 20px;
  padding-left: 10px;
  margin-top: 15px;
}

.market-update-card {
  background-color: #101419;
  width: 130px;
  display: flex;
  align-items: center;
  gap: 15px;
  padding: 5px 15px;
  border-radius: 10px;
}

.market-icon {
  color: rgb(242, 91, 91);
  font-weight: 200;
}

.market-update-card p {
  font-size: 15px;
  letter-spacing: 0.5px;
  font-weight: 400;
}

/* investing tools */
.investing-tools {
  margin: 20px 0;
  padding-left: 18px;
}

.investing-tools h2 {
  font-size: 16px;
  letter-spacing: 0.5px;
  font-weight: 600;
  color: #576678;
  text-transform: capitalize;
}

.investing-tools-cards {
  display: flex;
  gap: 20px;
  padding-left: 10px;
  margin-top: 15px;
  overflow-x: scroll;
}

.investing-tools-cards::-webkit-scrollbar {
  display: none;
}

.investing-card {
  background-color: #101419;
  display: flex;
  align-items: center;
  gap: 15px;
  padding: 5px 20px;
  border-radius: 10px;
  cursor: pointer;
}

.investing-card p {
  font-size: 15px;
  letter-spacing: 0.5px;
}

.investing-card .investing-icon {
  color: rgb(242, 91, 91);
  font-weight: 200;
}
