@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

.main-page {
  background-color: #101419;
  height: 600px;
  width: 400px;
  border-radius: 10px;
  position: absolute;
  top: 2%;
  left: 39%;
}

.main-page .app-logo h1 {
  font-size: 20px;
  font-weight: 700;
  color: #6d8096;
  letter-spacing: 0.5px;
  text-align: left;
  padding: 10px;
}

.main-page .text-container {
  padding: 20px;
  margin-bottom: 40px;
}

.text-container h2 {
  color: #029fed;
  font-size: 20px;
  font-weight: 600;
  text-transform: capitalize;
  letter-spacing: 0.5px;
}

.cards {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.register-card {
  display: flex;
  align-items: center;
  height: 80px;
  width: 300px;
  gap: 20px;
  background-color: #000000;
  margin-bottom: 10px;
  border-radius: 8px;
  padding: 10px;
  cursor: pointer;
}

.register-card .card-icon {
  color: #029fed;
}

.register-card h4 {
  color: #029fed;
}

.register-card p {
  color: #6d8096;
}

.bottom-container {
  position: absolute;
  bottom: 20px;
  left: 40px;
  align-items: center;
  display: flex;
  list-style-type: none;
  gap: 8px;
}

.bottom-container li a {
  color: #6d8096;
  font-size: 14px;
  padding-right: 4px;
  border-right: 1px solid #6d8096;
  cursor: pointer;
  text-decoration: none;
}
