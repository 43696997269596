.basket-page {
  height: 400px;
}

.basket-container {
  margin-top: 30px;
  background-color: #101419;
  padding: 10px 8px;
}

.basket-card {
  display: flex;
  align-items: center;
  gap: 10px;
  border-bottom: 2px solid #4a4545;
  padding: 10px 10px;
  cursor: pointer;
  margin-bottom: 10px;
}

.basket-card h4 {
  font-size: 15px;
  font-weight: 350;
  letter-spacing: 0.5px;
}

.basket-card span {
  background-color: rgb(72, 121, 72);
  padding: 5px 10px;
  border-radius: 4px;
  font-weight: 200;
  color: #eee;
  letter-spacing: 0.6px;
}

/* manage baskets */
.manage-baskets {
  display: flex;
  align-items: center;
  margin-top: 10px;
  gap: 10px;
  padding: 0 10px;
  color: #05a7f8;
}

.manage-basket-icon {
  cursor: pointer;
}
