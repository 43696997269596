@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

.search-page {
  background-color: #000000;
  color: white;
  height: 600px;
  width: 400px;
  border-radius: 10px;
  position: absolute;
  top: 2%;
  left: 39%;
  overflow-x: hidden;
  overflow-y: scroll;
}

.search-page::-webkit-scrollbar {
  display: none;
}

.search-header {
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 16px 8px;
  background-color: #101419;
}

.search-header .back-icon {
  cursor: pointer;
}

.search-input-div {
  display: flex;
  align-items: center;
  gap: 5px;
}

.search-input-div input {
  background-color: transparent;
  border: 2px solid #60696260;
  padding: 10px 10px;
  border-radius: 8px;
  outline: none;
  color: #fff;
  font-size: 15px;
  width: 250px;
}

.search-input-div input::placeholder {
  font-size: 15px;
  text-transform: capitalize;
}

.search-input-div .search-icon {
  cursor: pointer;
  background-color: #029fed;
  padding: 8px 6px;
  border-radius: 10px;
}

/* search results container */
.search-results-container {
  margin-top: 10px;
}

.search-results-container p {
  text-transform: uppercase;
  font-size: 15px;
  padding: 4px 10px;
  color: #ccd1cdce;
  letter-spacing: 0.2px;
}

.search-results-container p span {
  color: white;
}

/* results card */
.result-cards {
  height: 100%;
  width: 100%;
  background-color: #101419;
}

/* result card */
.result-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 5px 8px;
  padding: 10px;
  cursor: pointer;
  border-bottom: 1px solid #909490ce;
}

/* .result-card; */
.result-card h5 {
  margin-top: 2px;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 0.5px;
  margin-bottom: 4px;
}

.result-card h6 {
  font-size: 15px;
  font-weight: 300;
  margin-top: 8px;
}

.result-card h6 span {
  text-transform: uppercase;
  letter-spacing: 0.5px;
  margin-left: 4px;
}

.result-card .show-icon {
  color: #029fed;
  height: 30px;
  width: 30px;
  cursor: pointer;
}

.search-page .loader-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}
