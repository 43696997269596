@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

body {
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
  position: relative;
  box-sizing: border-box;
}

.login-page {
  background-color: #101419;
  height: 600px;
  width: 400px;
  border-radius: 10px;
  position: absolute;
  top: 2%;
  left: 39%;
}

.login-page .app-logo h1 {
  font-size: 20px;
  font-weight: 700;
  color: #6d8096;
  letter-spacing: 0.5px;
  text-align: left;
  padding: 10px;
}

.login-page .login-text-container {
  padding: 10px;
  margin-bottom: 10px;
}

.login-text-container .welcome-text {
  color: #029fed;
  font-size: 20px;
  font-weight: 600;
  text-transform: capitalize;
  letter-spacing: 0.5px;
  padding: 10px;
}

.login-text-container .welcome-text span {
  color: white;
}

.login-page .form-container {
  padding: 5px 20px;
  display: flex;
  gap: 20px;
  flex-direction: column;
}

.login-page .form-container .input-item {
  width: 90%;
  border-radius: 10px;
  padding: 15px 10px;
  border: 2px solid #6d8096;
  background-color: transparent;
  color: white;
}

.login-page .form-container input::placeholder {
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.5px;
}

.password-input {
  position: relative;
  width: 100%;
}

.password-input input {
  position: relative;
}

.password-input .eye-icon {
  position: absolute;
  top: 15px;
  color: white;
  height: 20px;
  width: 20px;
  right: 22px;
}

.login-page .forget-div {
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.login-page .forget-div .text-content .generate-otp {
  color: #029fed;
  font-size: 18px;
  font-weight: 600;
  cursor: pointer;
}

.login-page .login-btn {
  background-color: #029fed;
  color: white;
  padding: 12px 40px;
  border-radius: 12px;
  cursor: pointer;
  font-size: 15px;
  letter-spacing: 0.7px;
  font-weight: 500;
  border: none;
  margin: 15px 0;
}

.login-page .forget-div .forget-text {
  color: #029fed;
  font-size: 18px;
  font-weight: 600;
  cursor: pointer;
}

.bottom-container {
  position: absolute;
  bottom: 20px;
  left: 40px;
  align-items: center;
  display: flex;
  list-style-type: none;
  gap: 8px;
}


.bottom-container li a {
  color: #6d8096;
  font-size: 10px;
  padding-right: 4px;
  border-right: 1px solid #6d8096;
  cursor: pointer;
  text-decoration: none;
}

.login-page .loader-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 10px;
}
