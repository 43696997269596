.closed-top-container {
  display: flex;
  align-items: center;
  gap: 5px;
  margin: 10px 0;
  padding: 10px 20px;
}

.closed-top-container .top-container-icon {
  background-color: #101419;
  padding: 10px 10px;
  border-radius: 8px;
  cursor: pointer;
}

.closed-top-container .input-div {
  position: relative;
  padding: 10px 10px;
  border: 2px solid #655c5c;
  display: flex;
  align-items: center;
  border-radius: 12px;
  margin-right: 15px;
  width: 80%;
}

.closed-top-container .input-div .search-icon {
  margin-right: 8px;
  cursor: pointer;
}

.closed-top-container .input-div input {
  background-color: transparent;
  border: none;
  outline: none;
  color: white;
}

.closed-top-container .input-div input:focus {
  outline: none;
}

/* closed orders container */
.closed-orders-container.active {
  background-color: #101419;
  padding: 10px 8px;
  height: 400px;
  overflow-y: scroll;
}

.closed-orders-container.active::-webkit-scrollbar {
  display: none;
}

.closed-orders-container {
  background-color: #101419;
  padding: 10px 8px;
  height: 300px;
}

.closed-card {
  display: flex;
  justify-content: space-between;
  border-bottom: 2px solid #4a4545;
  padding: 5px 10px;
}

.closed-card .left span {
  font-size: 14px;
  font-weight: 400;
  color: #857e7e;
  margin-bottom: 8px;
}

.closed-card .left h3 {
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 10px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.closed-card .left p {
  font-size: 14px;
  margin-top: 10px;
  letter-spacing: 0.5px;
  font-weight: 300;
}

.closed-card .left p i {
  font-style: normal;
  text-transform: uppercase;
  color: #05a7f8;
}

/* :right */
.closed-card .right span {
  font-size: 14px;
  font-weight: 400;
  color: #857e7e;
  margin-bottom: 8px;
}

.closed-card .right h3 {
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 10px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.closed-card .right p {
  font-size: 14px;
  margin-top: 10px;
  letter-spacing: 0.5px;
  font-weight: 300;
  background-color: rgb(252, 78, 78);
  color: #fff;
  text-align: center;
}

.closed-orders-info {
  margin-top: 10px;
}

.closed-orders-info p {
  font-size: 13px;
  font-weight: 400;
  color: #857e7e;
}

/* empty closed order  */
.content-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 400px;
}

.content-page .text-container {
  width: 80%;
  text-align: center;
}

.content-page .text-container p {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.5px;
  text-transform: capitalize;
  color: #5f6164;
}
